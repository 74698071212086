<template lang="pug">
  .sessions
    .chip.active(v-for="(session, index) in sortSessionsList" :key="index + '_' + session.session")
      span.chip-label.unselectable {{ session.session.name }}
</template>

<script>
export default {
  name: 'CourseDaySessionsList',

  props: {
    sessionsList: Array
  },

  computed: {
    sortSessionsList: function() {
      if (!this.sessionsList) return
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.sessionsList.sort((a, b) => a.session.id - b.session.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.sessions {
  display: flex;
  padding: 5px 0;
  flex-direction: row;
}
</style>
